.textf{
    display: flex;
    flex-direction: row;
}

.audience{
    display: flex;
    flex-direction: row;
    justify-content:center;
    justify-content: space-evenly;
}

.cards{
    display: flex;
    flex-direction: column;
}

.cards2{
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-content: space-evenly;
}

.cards1{
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-content: space-evenly;
}