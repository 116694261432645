.top-hea{
    font-family: Sree Krushnadevaraya;
font-size: 40px;
font-weight: 400;
line-height: 24px;
text-align: left;

}
.div-1{
    width: 566px;
    height: 592px;
    padding: 20px 20px;
    top: 214px;
    left: 320px;
    gap: 0px;
    border-radius: 20px 20px 20px 20px;
    opacity: 0px;
    background: #F9F9F9B2;
    box-shadow: 0px 4px 4px 0px #00000040;
}
.hea-2{
    font-family: Sree Krushnadevaraya;
font-size: 30px;
font-weight: 400;
line-height: 24px;
text-align: left;
}
.upload{
    
}
.upload-icon{
    width: 85px;
    border-radius: 5px;
height: 82px;
top: 308px;
left: 348px;
gap: 0px;
padding: 20px;
opacity: 0px;
background: #BDDCFF;
border-color: #669ADA;
border-style: 3px dotted;
background: #BDDCFF;
}

.input-title{
width: 100%;
height: 45px;
top: 305px;
left: 446px;
/* padding: 12.8px 109px 11.4px 14.8px; */
gap: 0px;
border-radius: 5px 0px 0px 0px;
border: 1px 0px 0px 0px;
opacity: 0px;
background: #FFFFFF;
border: 1px solid #CCCCCC;
}
.input-Description{
    width: 407px;
height: 79px;
top: 352px;
left: 446px;
gap: 0px;
border-radius: 5px 0px 0px 0px;
border: 1px 0px 0px 0px;
opacity: 0px;

}.link-icon{
height: 27px;
}
.push-img-text{
    font-size: 15.5px;
}
.date-inputbox{
    width: 497px;
height: 45px;
top: 571px;
left: 356px;
padding: 11px 172.2px 10px 12px;
gap: 0px;
border-radius: 6px 6px 6px 6px;
border: 1px 0px 0px 0px;
opacity: 0px;
background: #F9F9F9B2;
box-shadow: 0px 4px 4px 0px #00000040;

}
.select-inputbox{
    width: 100%;
height: 45px;

padding: 12.8px 18.94px 11.4px 14.8px;

border-radius: 5px 5px 5px 5px;
border: 1px 0px 0px 0px;
opacity: 0px;
background: #FFFFFF;
border: 1px solid #CCCCCC;
}
.send-btn{
    width: 229px;
height: 42px;
top: 731px;
left: 489px;
display: flex;
justify-content: center;
margin: auto;
padding: 7.6px 32px 10.4px 31px;
gap: 0px;
color: white;
border-radius: 6px;
opacity: 0px;
background: #445490;
box-shadow: 0px 1px 5px 0px #919EAB1F;

box-shadow: 0px 2px 2px 0px #919EAB24;

box-shadow: 0px 3px 1px -2px #919EAB33;

}
.total-spend{
    width: 247px;
height: 129px;
top: 214px;
left: 929px;
text-align: center;

gap: 0px;
border-radius: 15.5px;
opacity: 0px;
background: #ECF2FDCC;
box-shadow: 0px 4px 4px 0px #00000040;

}
.payments{
    width: 248px;
height: 128px;
top: 369px;
left: 929px;
gap: 0px;
border-radius: 20px 0px 0px 0px;
opacity: 0px;
background: #F9F9F9B2;
box-shadow: 0px 4px 4px 0px #00000040;

}
.total-graph{
    width: 248px;
height: 283px;
top: 522px;
left: 929px;
gap: 0px;
border-radius: 20px;
opacity: 0px;
background: #EFF8F3;

}
.cards{
    width: 263px;
height: 60px;
top: 214px;
left: 1221px;
gap: 0px;
border-radius: 15px;
opacity: 0px;
background: #F9F9F9B2;
box-shadow: 0px 4px 4px 0px #00000040;
text-align: center;
font-size: 20px;
margin-bottom: 10px;
padding-top: 15px;
}
.upload{
    border-style: dashed;
    border-color: #4695f5;
    border-radius: 8px;
    height: 88px;
}















/* 
----------------------- */

.save-div{
    width: 1166px;
height: 1306px;

gap: 0px;
margin: auto auto;
border-radius: 20px;
margin-top: 100px;
opacity: 0px;
background: #F9F9F9B2;
box-shadow: 0px 4px 4px 0px #00000040;

}
.head{
    justify-content: space-between;
}
.your-temp-heading{
    font-size: 20px;
}
.delete-btn{
    width: 97px;
height: 40px;
top: 1000px;
left: 1355px;
gap: 0px;
border-radius: 10px;
border: 1px 0px 0px 0px;
opacity: 0px;
background: #FFFFFF;
border: 1px solid #000000;
color: #FF0000;

}


.icons{
   font-size: 50px;
}
.text{
    font-size: 25px;
}
.width{
    width: 200px;
    margin-left: 5px;
}
.widths{
    width: 450px;
}