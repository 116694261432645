.box1{
    background-image: url("./image1.jpg");
    width: 100%;
    height: 800px;
}

.box2 {
    background-color: rgb(99, 140, 176);
    display: flex;
    flex-direction: row;
}

.heading {
    
    text-align: center;
    margin-right: 400px;
 
}

.image-grid {
   
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px;
}

.image-grid img {
    width: 100%;
    height:auto;
    border-radius: 5px;
}