.camp{
    display: flex;
    flex-direction: row;
}

.transparent{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.main-testi{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.testi{
    display: flex;
    flex-direction: row;
}