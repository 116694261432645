.monoton-regular {
  font-family: "Monoton", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.custom-input {
  background-color: #FFFFFF !important;
  /* border : 1px solid black ; */
  &:focus {
    border-color: #000000 !important;
  }
  &:hover {
    border-color: #000000 !important;
  }
  &::placeholder {
    color: #000000 !important;
  }
  opacity:100%
}